@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";

.navbar-collapse-position {
    position: absolute;

    @include media-breakpoint-up(sm) {
        position: static;
    }
}

.collapsing {
    position: absolute !important;
}
